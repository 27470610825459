import { FC } from "react";
import styled from "styled-components";
import { WorkoutCard } from "../cards/WorkoutCard";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  margin-bottom: 8px;
`;

interface Props {
  title: string;
  id: string;
}

export const WorkoutCarouselItem: FC<Props> = ({ title, id }) => {
  return (
    <Container>
      <ItemTitle>{title}</ItemTitle>
      <WorkoutCard workoutId={id} />
    </Container>
  );
};
